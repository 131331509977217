import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FacebookShareButton, RedditShareButton, TwitterShareButton, FacebookMessengerShareButton, TelegramShareButton, ViberShareButton, WhatsappShareButton, WhatsappIcon, EmailIcon, TelegramIcon, ViberIcon, EmailShareButton, FacebookIcon, RedditIcon, FacebookMessengerIcon, XIcon } from 'react-share';
import { BASE_URL, POST_DIR } from '../../../config';

function ShareThis({ id, url = "", image_url = "" }) {

  const build_url = `${BASE_URL}${POST_DIR}/${id.id}`;
  const title = id.title;
  const description = id.description;
  const hashtag = ['WaguDiet', 'MaverickMidas'];

  return (
    <Row>
      <Col>
        <EmailShareButton url={build_url} subject={title} body={description}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <FacebookShareButton url={build_url}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <FacebookMessengerShareButton url={build_url}>
          <FacebookMessengerIcon size={32} round={true} />
        </FacebookMessengerShareButton>
        <TwitterShareButton url={build_url} title={`${title} -`} hashtags={hashtag}>
          <XIcon size={32} round={true} />
        </TwitterShareButton>
        <RedditShareButton url={build_url} title={title}>
          <RedditIcon size={32} round={true} />
        </RedditShareButton>
        <TelegramShareButton url={build_url} title={title}>
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
        <ViberShareButton url={build_url} title={title}>
          <ViberIcon size={32} round={true} />
        </ViberShareButton>
        <WhatsappShareButton url={build_url} title={title}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </Col>
    </Row >
  );
}

export default ShareThis;
