import React from 'react';
import StandardPage from './layout/StandardPage';
import { Link } from 'react-router-dom';

function Contact() {
    return (
        <StandardPage title="Contact">
            <br /><br />
            <h2>Maverick Midas<br />
                <Link to="mailto:maverick@wagudiet.com">maverick@wagudiet.com</Link>
            </h2>
            <br /><br />
        </StandardPage>
    );
}

export default Contact;