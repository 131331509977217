import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function MainLayoutWrapper({ children }) {
    return (
        <Container fluid className="mainLayoutWrapper p-0">
            <Row>
                <Col lg={{ span: 8, offset: 4 }} className='postContainer p-0 p-md-2'>
                    {children}
                </Col>
            </Row>
        </Container>
    );
}

export default MainLayoutWrapper;