import React from "react";


const TagsMod = ({ children, id }) => {
    return (
        <div><span className="tagLabel">Tags:</span>
            {children.map((child, index) => (
                <span className="tagStyle" key={`${id}-${index}`}><a href={`?tag=${child}`}>{child}</a></span>
            ))}
        </div>
    );
}

export default TagsMod;
