import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import EpisodeBreadcrumb from './episode/Breadcrumbs';
import { DATA_SOURCE } from '../config';
import EpisodeBase from './episode/EpisodeBase';


function EpisodeDetail() {
  const { episodeId } = useParams(); // Access URL parameter
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`../..${DATA_SOURCE}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const foundEpisode = data.episodes.find(ep => ep.id.toString() === episodeId);
        const foundPost = data.standard_posts.find(post => post.id.toString() === episodeId);
        setItem(foundEpisode || foundPost);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching episode:', error);
        setError(error.toString());
        setLoading(false);
      });
  }, [episodeId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!item) return <div>No data found</div>;

  const fullTitle = item.audio_file ? `Episode #${item.id} - ${item.title}` : item.title;

  return (
    <Container fluid className='p-0'>
      <Col className='ps-2'><EpisodeBreadcrumb currentItem={fullTitle} /></Col>
      <EpisodeBase currentItems={[item]} />
    </Container>
  );
}

export default EpisodeDetail;
