import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Episode from './episode/Episode';

function Home() {
    return (
        <Container fluid="fluid"><br />
            <Row>
                <Col>
                    <Episode episodesPerPg={3} />
                </Col>
            </Row>
        </Container>
    );
}

export default Home;