import React from 'react';
import EpisodeBreadcrumb from './episode/Breadcrumbs';
import Player from './episode/Player';
import Container from 'react-bootstrap/Container';

function EpisodeList() {


    return (
        <Container fluid="fluid" id="playerPlaylist">
            <EpisodeBreadcrumb />
            <Player showPlaylistWindow={true} />
            {/*<Episode episodesPerPg={2} />*/}
        </Container>
    );
}

export default EpisodeList;
