import React from "react";
import Card from 'react-bootstrap/Card';

function EpisodeTranscript({ children }) {
    // Convert text to handle newlines and bold formatting
    const formattedText = children.split(/(?:\r\n|\r|\n)/g).map((line, index) => (
        <React.Fragment key={index}>
            {formatBoldText(line)}
            <br />
        </React.Fragment>
    ));

    return (
        <Card className="mt-4">
            <Card.Body className="transcriptWindow">
                <p>
                    {formattedText}
                </p>
            </Card.Body>
        </Card>
    );
}

// Function to handle bold formatting
function formatBoldText(text) {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const parts = text.split(boldRegex);

    return parts.map((part, index) =>
        index % 2 === 1 ? <strong key={index}>{part}</strong> : part
    );
}

export default EpisodeTranscript;
