import Breadcrumb from 'react-bootstrap/Breadcrumb';

function EpisodeBreadcrumb({currentItem}) {

  return (
    <Breadcrumb>
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item href="/episode/">
        Episodes
      </Breadcrumb.Item>
      <Breadcrumb.Item active>{currentItem}</Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default EpisodeBreadcrumb;