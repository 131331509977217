import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function StandardPage({ children = 'Coming soon.', title, id = null }) {

    return (
        <div className="mt-4 p-3 mb-4 standard_panel rounded-3 text-light" id={id}>
            <Container fluid="fluid">
                <Row>
                    <Col>
                        <h1 className="display-5 fw-bold">{title}</h1>
                        <div className="col-md-8 fs-4">{children}</div>
                    </Col>
                </Row>
            </Container>
        </div >
    );
}

export default StandardPage;