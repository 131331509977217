import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";

function SourceList({ children, style = "" }) {
  return (
    <div>
      {style === "accordion" ? (
        <Accordion defaultActiveKey="0" flush className="pt-4 rounded-3">
          {children.map(child => (
            <Accordion.Item key={child.id}>
              <Accordion.Header>{child.title}</Accordion.Header>
              <Accordion.Body>
                {child.url !== undefined ? (
                  <ul className='mb-0'>
                    <li>
                      <Link to={child.url} target="_blank">[Link]</Link> <span>{child.description}</span>
                    </li>
                  </ul>
                ) : "No Linked Sources."}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      ) : (
        <ul>
          {children.map(child => (
            <li key={child.id}>
              {child.url !== undefined ? (
                <>
                  <Link to={child.url} target="_blank">[Link]</Link> <span>{child.title} {child.description}</span>
                </>
              ) : (
                <span>{child.title}: No Linked Sources.</span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SourceList;
