import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { DATA_SOURCE } from '../../config';


function Player({ showPlaylistWindow, id }) {
    const [episodes, setEpisodes] = useState([]);
    const [playlist, setPlaylist] = useState([]);
    const [currentTrack, setTrackIndex] = useState(0);
    const [durations, setDurations] = useState({});
    const [sortAsc, setSortAsc] = useState(true); // true for oldest to newest, false for newest to oldest
    const navigate = useNavigate(); // Use navigate hook

    useEffect(() => {
        fetch(DATA_SOURCE)
            .then(response => response.json())
            .then(data => {
                const sortedEpisodes = data.episodes.sort((a, b) => b.id - a.id);
                setEpisodes(sortedEpisodes);
                setPlaylist(sortedEpisodes);
                sortedEpisodes.forEach(item => {
                    getAudioDuration(item.src, item.id);
                });
            })
            .catch(error => console.error('Error loading the data', error));
    }, []);

    useEffect(() => {
        if (id && episodes.length > 0) {
            const episodeIndex = episodes.findIndex(episode => episode.id === id);
            if (episodeIndex !== -1) {
                setTrackIndex(episodeIndex);
            }
        }
    }, [id, episodes]);

    const handleClickNext = () => {
        setTrackIndex(currentTrack => (currentTrack < playlist.length - 1 ? currentTrack + 1 : 0));
    };

    const handleEnd = () => {
        handleClickNext();
    };

    const handleSelectAudio = (event, index) => {
        event.preventDefault();
        setTrackIndex(index);
    };

    const formatDuration = (seconds) => {
        const date = new Date(0);
        date.setSeconds(seconds);
        return date.toISOString().substr(14, 5);
    };

    const handleSort = () => {
        const sorted = [...playlist].sort((a, b) => {
            return sortAsc ? new Date(a.date_posted) - new Date(b.date_posted) : new Date(b.date_posted) - new Date(a.date_posted);
        });
        setPlaylist(sorted);
        setSortAsc(!sortAsc); // Toggle sorting order for next click
    };

    const getAudioDuration = (audioUrl, id) => {
        const audio = new Audio(audioUrl);
        audio.addEventListener('loadedmetadata', () => {
            setDurations(prevDurations => ({
                ...prevDurations,
                [id]: audio.duration
            }));
        });
    };

    const handleNavigate = (episodeId) => {
        navigate(`/episode/${episodeId}`);
    };

    const playlist_window = showPlaylistWindow ? "rounded-top-3" : "rounded-3";

    return (
        <Row>
            <Col>
                {showPlaylistWindow ? (
                    <div>
                        <div className='audioPlayerTicker'>{playlist[currentTrack]?.title}</div>
                        <AudioPlayer
                            volume={0.5}
                            src={playlist[currentTrack]?.src}
                            showSkipControls
                            onClickNext={handleClickNext}
                            onEnded={handleEnd}
                            onError={() => console.log('play error')}
                            className={playlist_window}
                            autoPlay={false}
                        />
                        <div className='playlist_window'>

                            <ul>
                                <li className='playlistHeading'>
                                    <Col xs={{ span: 12 }} md={{ span: 3 }} xl={{ span: 3 }}>Name</Col>
                                    <Col xs={{ span: 12 }} md={{ span: 3 }} >Date Posted</Col>
                                    <Col xs={{ span: 12 }} md={{ span: 3 }} >Page Link</Col>
                                    <Col xs={{ span: 6 }} md={{ span: 3 }} >Duration</Col>
                                </li>
                                {playlist.map((item, index) => (
                                    <li key={item.id} className={`col-12${index === currentTrack ? ' active' : ''} d-flex`} onClick={(e) => handleSelectAudio(e, index)}>
                                        <Col xs={{ span: 12 }} md={{ span: 3 }} ><span className='track_name'>{item.title}</span></Col>
                                        <Col xs={{ span: 12 }} md={{ span: 3 }} > <FontAwesomeIcon icon={faAnglesRight} /> <span className="date_posted">{item.date_posted}</span> {index === currentTrack ? <FontAwesomeIcon icon={faVolumeHigh} /> : ""}</Col>
                                        <Col xs={{ span: 12 }} md={{ span: 3 }} ><Button variant="link" onClick={() => handleNavigate(item.id)} >Link</Button></Col>
                                        <Col xs={{ span: 6 }} md={{ span: 3 }} ><span className='trackDuration'>{durations[item.id] ? formatDuration(durations[item.id]) : 'Loading...'}</span></Col>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='bottom_bar rounded-bottom-3'><Button variant='outline-secondary' className='text-light rounded-top-0 rounded-end-0 sortButton' onClick={handleSort}>Sort {sortAsc ? "Newest" : "Oldest"}</Button></div>
                    </div>
                ) : (
                    <div>
                        <AudioPlayer
                            volume={0.5}
                            autoPlay={false}
                            autoPlayAfterSrcChange={false}
                            src={playlist[currentTrack]?.src}
                            onError={() => console.log('play error')}
                        />
                    </div>
                )}
            </Col>
        </Row>
    );
}

export default Player;
