// FilterControls.js
import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

function FilterControls({ searchTerm, setSearchTerm, checkboxFilter, setCheckboxFilter, searchCriteria, setSearchCriteria, handleSearch }) {
    return (
        <Form className='mb-4'>
            <Form.Group className="filter-controls">
                <Row>
                    <Col xs={{ span: 4 }} md={{ span: 4 }} xl={{ span: 2 }}>
                        <Form.Control as="select" value={searchCriteria} onChange={(e) => setSearchCriteria(e.target.value)}>
                            <option value="title">Title</option>
                            <option value="description">Description</option>
                            <option value="tags">Tags</option>
                            <option value="content">Content</option>
                        </Form.Control>
                    </Col>
                    <Col xs={{ span: 6 }} md={{ span: 4 }} xl={{ span: 3 }} className='ps-lg-0'>
                        <Form.Control type="text" placeholder='Search...' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </Col>
                    <Col className='ps-lg-0'>
                        <Button variant="dark" onClick={handleSearch} className='me-3'>
                            Search
                        </Button>
                        <Button variant="dark" type="reset" className='me-3' href="/">
                            Clear Filters
                        </Button>
                        <Form.Label className='d-inline-flex pt-1'><Form.Check type="checkbox" checked={checkboxFilter}
                            onChange={(e) => setCheckboxFilter(e.target.checked)} className='pe-2' /> Non Podcast Episode
                        </Form.Label>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    );
}

export default FilterControls;
