import React from 'react';
import StandardPage from './layout/StandardPage';
import { Container } from 'react-bootstrap';


function FAQ() {
    return (
        <StandardPage title="Frequently Asked Questions" id="faq_page">
            <Container fluid>
                <br /><h2>Where are the diet tips?</h2>
                <p>New diet. Who dis? <br />The name comes from eating a diet of only the finest. Just enjoy it.</p>
            </Container>
        </StandardPage>
    );
}

export default FAQ;