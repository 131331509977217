import React from 'react';
import NavBar from "./Navbar";
import './layout.css';
import MainLayoutWrapper from './MainLayoutWrapper';
import { COMPANY_NAME } from '../../config';


function FullPageLayout({ children }) {
    return (
        <div className="layout">
            <header>
                <NavBar />
            </header>
            <main className="content">
                <MainLayoutWrapper>
                    {children}
                </MainLayoutWrapper>
            </main>
            <footer className="footer">
                © 2024 {COMPANY_NAME}
            </footer>
        </div>
    );
}

export default FullPageLayout;
