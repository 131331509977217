// Episode.js
import React, { useState, useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import FilterControls from './FilterControls';
import Pagination from './Pagination';
import { DATA_SOURCE } from '../../config';
import EpisodeBase from './EpisodeBase';
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Episode({ episodesPerPg = 1 }) {
    const query = useQuery();
    const tag = query.get('tag');
    const [episodes, setEpisodes] = useState([]);
    const [standardPosts, setStandardPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [episodesPerPage] = useState(episodesPerPg);
    const [searchTerm, setSearchTerm] = useState(tag || '');
    const [checkboxFilter, setCheckboxFilter] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState('title');
    const [filteredEpisodes, setFilteredEpisodes] = useState([]);
    const [filteredStandardPosts, setFilteredStandardPosts] = useState([]);

    useEffect(() => {
        fetch(DATA_SOURCE)
            .then(response => response.json())
            .then(data => {
                if (data.episodes && data.standard_posts) {
                    const sortedEpisodes = data.episodes.sort((b, a) => new Date(a.date_posted) - new Date(b.date_posted));
                    const sortedStandardPosts = data.standard_posts.sort((b, a) => new Date(a.date_posted) - new Date(b.date_posted));
                    setEpisodes(sortedEpisodes);
                    setStandardPosts(sortedStandardPosts);
                } else {
                    console.error('Data does not contain episodes or standard_posts');
                }
            })
            .catch(error => console.error('Error loading the data', error));
    }, []);

    const matchesSearchTerm = useCallback((item) => {
        const term = searchTerm.toLowerCase();
        switch (searchCriteria) {
            case 'title':
                return item.title.toLowerCase().includes(term);
            case 'description':
                return item.description.toLowerCase().includes(term);
            case 'tags':
                return item.tags && item.tags.join(' ').toLowerCase().includes(term);
            case 'content':
                return item.content && item.content.toLowerCase().includes(term);
            default:
                return false;
        }
    }, [searchTerm, searchCriteria]);

    const handleSearch = useCallback(() => {
        const filteredEpisodes = episodes.filter(episode => {
            const matchesCheckbox = checkboxFilter ? episode.someCheckboxProperty : true; // Adjust this line as needed
            return matchesSearchTerm(episode) && matchesCheckbox;
        });

        const filteredStandardPosts = standardPosts.filter(post => matchesSearchTerm(post));

        setFilteredEpisodes(filteredEpisodes);
        setFilteredStandardPosts(filteredStandardPosts);
        setCurrentPage(1); // Reset to first page on search
    }, [matchesSearchTerm, checkboxFilter, episodes, standardPosts]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    const combinedItems = [...filteredEpisodes, ...filteredStandardPosts];

    // Ensure that combinedItems contains unique items
    const uniqueCombinedItems = Array.from(new Set(combinedItems.map(item => item.id)))
        .map(id => combinedItems.find(item => item.id === id));

    const indexOfLastItem = currentPage * episodesPerPage;
    const indexOfFirstItem = indexOfLastItem - episodesPerPage;
    const currentItems = uniqueCombinedItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <Container fluid className='p-0'>
            <FilterControls
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                checkboxFilter={checkboxFilter}
                setCheckboxFilter={setCheckboxFilter}
                searchCriteria={searchCriteria}
                setSearchCriteria={setSearchCriteria}
                handleSearch={handleSearch}
            />
            <EpisodeBase currentItems={currentItems} />
            <Pagination
                totalItems={uniqueCombinedItems.length}
                itemsPerPage={episodesPerPage}
                currentPage={currentPage}
                paginate={paginate}
            />
        </Container>
    );
}

export default Episode;
