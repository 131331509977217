import React from "react";
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import TagsMod from './EpisodeBarComponents/TagsMod';
import Player from "./Player";
import EpisodeBar from "./EpisodeBar";

const EpisodeBase = ({ currentItems }) => {
    return (
        <div>
            {currentItems.map(item => (
                <Card key={item.id} className="mb-4 rounded-3 text-light episode_panel" data-bs-theme="dark">
                    <Card.Body>
                        <Row>
                            <Col className='mx-xl-3 mx-xxl-0' >
                                <span className='h1 episodeTitle'>
                                    <Link to={`/episode/${item.id}`}>#{item.id} - {item.title}</Link>
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <span className='data_posted'>
                                {item.date_posted}
                            </span>
                        </Row>
                        <Container fluid className='float-left episode_audio mb-0'>
                            <Row>
                                {item.src !== undefined ? <Col className='p-3 summaryPanel' lg={{ span: 6 }}>
                                    {item.description}
                                </Col> : <Col className='p-3 summaryPanel full' lg={{ span: 12 }}>
                                    {item.description}
                                </Col>}
                                {item.src !== undefined ? <Col className='p-3 audioPanel' lg={{ span: 6 }}>
                                    {item.audio_file && (
                                        <div>
                                            {item.title}
                                            <Player id={item.id} />
                                        </div>
                                    )}
                                </Col> : false}
                            </Row>
                        </Container>
                        <Container className="pb-3">
                            <Row>
                                <Col></Col>
                                <Col md={{ span: 5 }} xxl={{ span: 3, offset: 2 }} className='pt-md-0 pt-xxl-2 tagContainer'>
                                    <TagsMod id={item.id}>
                                        {item.tags}
                                    </TagsMod>
                                </Col>
                            </Row>
                        </Container>
                        {item.transcript || (item.sources && item.sources.length > 0) ? (
                            <EpisodeBar transcript={item.transcript} item={item} />
                        ) : null}
                    </Card.Body>
                </Card>
            ))
            }
        </div>
    )
}

export default EpisodeBase;