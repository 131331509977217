import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import crown from '../../img/wagu-crown-04.svg';
import { useLocation } from 'react-router-dom';


function NavBar() {
    const location = useLocation();
    const getNavLinkClass = (path) => {
        return location.pathname === path ? 'link-light active' : 'link-light';
    }

    return (
        <div>
            <Navbar expand="lg" className="text-light bg-dark" fixed="top">
                <Container fluid="fluid">
                    <Navbar.Brand href="/" className='link-light col-9 col-xl-2 text-center'>WAGU<img src={crown} className='crown_icon' alt="Crown" />DIET</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className='text-light link-light bg-dark col-2 col-sm-1' id='navtoggle'>
                        <FontAwesomeIcon icon={faBars} />
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/" className={getNavLinkClass('/')}>Home</Nav.Link>
                            <Nav.Link href="/episode/" className={getNavLinkClass('/episode/')}>Episodes</Nav.Link>
                            <Nav.Link href="/about/" className={getNavLinkClass('/about/')}>About</Nav.Link>
                            <Nav.Link href="/faq/" className={getNavLinkClass('/faq/')}>FAQ</Nav.Link>
                            <Nav.Link href="/contact/" className={getNavLinkClass('/contact/')}>Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default NavBar;
