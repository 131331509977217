import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import FullPageLayout from './components/layout/FullPageLayout';
import EpisodeDetail from './components/EpisodeDetail';
import NotFoundPage from './components/NotFoundPage';
import EpisodeList from './components/EpisodeList';
import About from './components/About';
import Contact from './components/Contact';
import FAQ from './components/FAQ';

function App() {
  return (
    <Router>
      <FullPageLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/episode/:episodeId" element={<EpisodeDetail />} />
          <Route path="/episode" element={<EpisodeList />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </FullPageLayout>
    </Router>
  );
}

export default App;
