import React, { useState } from "react";
import SourceList from "./EpisodeBarComponents/SourceList";
import EpisodeTranscript from "./EpisodeBarComponents/EpisodeTranscript";
import ShareThis from "./EpisodeBarComponents/ShareThis";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/esm/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Collapse from 'react-bootstrap/Collapse';

const EpisodeBar = ({ item, transcript }) => {

    const [open, setOpen] = useState('share'); // Default open panel

    const togglePanel = (panel) => {
        if (open === panel) {
            setOpen(null); // Close the panel if it is already open
        } else {
            setOpen(panel); // Open the clicked panel
        }
    };

    let sourcesTitle = item.sources && item.sources.length > 0 ? 'Sources Mentioned' : 'No Sources Mentioned';

    return (
        <Row>
            <Col className='rounded-3 text-center'>
                <ButtonGroup aria-label="Episode Bar">
                    <Button variant="dark"
                        onClick={() => togglePanel('share')}
                        aria-controls="ShareEpisode"
                        aria-expanded={open === 'share'}
                        active={open === 'share'}
                    >
                        Share This Episode
                    </Button>
                    {transcript && transcript.trim() !== "" && (
                        <Button variant="dark"
                            onClick={() => togglePanel('transcript')}
                            aria-controls="episodeTranscriptWindow"
                            aria-expanded={open === 'transcript'}
                            active={open === 'transcript'}
                        >
                            Transcript of Episode
                        </Button>
                    )}
                    <Button variant="dark"
                        onClick={() => togglePanel('sources')}
                        aria-controls="sourcesMentioned"
                        aria-expanded={open === 'sources'}
                        active={open === 'sources'}
                    >
                        Sources Mentioned
                    </Button>
                </ButtonGroup>
            </Col>
            <Row>
                <Collapse in={open === 'share'}>
                    <Col className="pt-4">
                        <div id="ShareEpisode" className="p-2">
                            <span className="ep_mod_title">Share This</span>
                            <ShareThis id={item} />
                        </div>
                    </Col>
                </Collapse>
            </Row>
            <Row>
                <Collapse in={open === 'sources'}>
                    <Col className="pt-4">
                        <div id="sourcesMentioned" className="p-2">
                            <span className="ep_mod_title">{sourcesTitle}</span><br />
                            <SourceList>{item.sources}</SourceList>
                        </div>
                    </Col>
                </Collapse>
            </Row>
            {transcript && transcript.trim() !== "" && (
                <Row>
                    <Collapse in={open === 'transcript'}>
                        <Col className="pt-4">
                            <div id="episodeTranscriptWindow" className="p-2">
                                <span className="ep_mod_title">Episode Transcript</span><br />
                                <EpisodeTranscript>{item.transcript}</EpisodeTranscript>
                            </div>
                        </Col>
                    </Collapse>
                </Row>
            )}
        </Row>
    );
}

export default EpisodeBar;
